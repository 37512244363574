import { WebConfigResponse, WebSearchResponse } from '@ig-ds/common-types';
import axios from 'axios';
import { Environment, Auth } from '@ig-ds/utilities';
import log from '@ig-ds/utilities/src/log/log';

export const getConfig = async (host?: string): Promise<WebConfigResponse> => {
  const baseUrl = Environment.getConfigApiBaseUrl(Environment.getEnvironmentFromHost(host));
  const platform = 'WTP';
  const { CST } = Auth.getAuth();

  const { data } = await axios
    .get<WebConfigResponse>(`${baseUrl}/web/configuration`, {
      headers: {
        'x-device-user-agent': `vendor=IG Group | applicationType=ig | platform=${platform} | version=1.0.0`,
        CST,
      },
    })
    .then((res) => res)
    .catch((err) => {
      log.error(err);
      throw err;
    });

  return data;
};

export const getSearchResults = async (
  searchText: string,
  options: { perPage?: number; page?: number } = { perPage: 5, page: 1 },
  host?: string,
): Promise<WebSearchResponse | null> => {
  const baseUrl = Environment.getConfigApiBaseUrl(Environment.getEnvironmentFromHost(host));
  const platform = 'WTP';

  const { siteId, CST } = Auth.getAuth();
  const { data } = await axios
    .get<WebSearchResponse>(`${baseUrl}/web/search`, {
      headers: {
        'x-device-user-agent': `vendor=IG Group | applicationType=ig | platform=${platform} | version=1.0.0`,
        CST,
      },
      params: { q: searchText, siteId, page: options.page, per_page: options.perPage },
    })
    .then((res) => res)
    .catch((err) => {
      log.error(err);
      return { data: null };
    });

  return data;
};
