import * as Sentry from '@sentry/browser';
import { ErrorInfo } from 'react';
import env from '../environment/environment';

const error = (err: Error, info?: ErrorInfo) => {
  const environment = env.getEnvironmentFromHost();

  if (environment === 'UAT' || environment === 'PROD') {
    Sentry.captureException(err);
  } else {
    // eslint-disable-next-line no-console
    console.error(err, info);
  }
};

export default {
  error,
};
