import { WebConfigSearchResponse } from '@ig-ds/common-types';
import { Header, Link, Paragraph } from '@ig-phoenix/atoms';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import AppWrapper from '../components/AppWrapper';
import ResultsList from '../components/ResultsList';
import { getConfig } from '../utils/api';

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.color.background.secondarySurface.value};
  padding-top: 8px;
  padding-bottom: 16px;
`;

export interface LocationState {
  searchParam: string;
  section: 'help-and-support' | 'community';
  maxResults: number;
}

const ResultsPage: FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState | null;

  const [searchConfig, setSearchConfig] = useState<WebConfigSearchResponse | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getConfig().then((res) => {
      const config = res?.sections.find((section) => section.type === 'SEARCH') as WebConfigSearchResponse;
      setSearchConfig(config || undefined);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return null;
  }

  if (state === null || searchConfig === undefined) {
    return <Navigate to='/'></Navigate>;
  }

  const { searchParam, section, maxResults } = state;
  const inSectionText = section === 'community' ? searchConfig?.inSupportCommunity : searchConfig?.inSupportArticles;

  return (
    <AppWrapper>
      <StickyHeader>
        <Link onClick={() => navigate('/')}>{intl.formatMessage({ id: 'back.button' })}</Link>
        <Header size='medium' variant='h3' textAlign='left' marginTop='16px'>
          {`${maxResults} ${searchConfig.resultCountFor} "${searchParam}"`}
        </Header>
        <Paragraph marginTop='8px' variant='tertiary'>
          {inSectionText}
        </Paragraph>
      </StickyHeader>
      <ResultsList
        {...state}
        translations={{
          errorCta: searchConfig.tryAgain,
          errorText: searchConfig.errorLoadingSearchResults,
          lastResultText: searchConfig.noMoreSearchResults,
        }}
      />
    </AppWrapper>
  );
};

export default ResultsPage;
