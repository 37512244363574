import { ErrorBoundary, Sentry } from '@ig-ds/utilities';
import { MatterFonts } from '@ig-phoenix/fonts';
import { StyleProvider } from '@ig-phoenix/style-provider';
import { createContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { MemoryRouter, Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Home from './pages/Home';
import Results from './pages/Results';
import messages from './locales/en-GB.json';

declare const VERSION: string;
interface Props {
  name: string;
  host?: string;
}

const GlobalStyles = createGlobalStyle`
  html, body, #root { 
    background: ${({ theme }) => theme.color.background.secondarySurface.value};
    height: 100%;
  }
`;

export const HostContext = createContext('');

const Root = ({ name, host = '' }: Props): JSX.Element => {
  useEffect(() => {
    Sentry.init({
      dsn: 'https://b0d32d6604d14edb98f52f13b0a096fc@o37925.ingest.sentry.io/6070854',
      version: VERSION,
    });
  }, [Sentry]);

  return (
    <ErrorBoundary>
      <MemoryRouter>
        <IntlProvider locale='en-GB' messages={messages}>
          <StyleProvider themeSet='IGWebRebrand' colorScheme='Light'>
            <MatterFonts />
            <GlobalStyles />
            <HostContext.Provider value={host}>
              <Routes>
                <Route path='*' element={<Home name={name} />} />
                <Route path='/results' element={<Results />} />
              </Routes>
            </HostContext.Provider>
          </StyleProvider>
        </IntlProvider>
      </MemoryRouter>
    </ErrorBoundary>
  );
};

export default Root;
