import { Box, Carousel } from '@ig-phoenix/molecules';
import { VFC, useEffect, useState } from 'react';
import { WebConfigAdditionalLinksResponse } from '@ig-ds/common-types';
import { calculateThemeColor } from '@ig-ds/utilities';
import styled, { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import SectionHeader from './SectionHeader';

type LogoMap = {
  [key: string]: JSX.Element;
};

const BoxContainer = styled.div`
  margin: 0 5px;
`;

const TextContainer = styled.div`
  text-align: left;
  width: 80%;
`;

const responsiveObject = {
  superLargeDesktop: {
    breakpoint: { max: 100000, min: 1025 },
    items: 5,
  },
  largeDesktop: {
    breakpoint: { max: 1024, min: 841 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 840, min: 641 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 640, min: 471 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 470, min: 0 },
    items: 2,
  },
};

const handleClick = (url: string) => {
  window.open(url);
};

const returnLogoMap = async (theme: string) => {
  const ContactUsIcon = await import(`./icons/${theme}/additionalLinks/ContactUs`);
  const CommunityIcon = await import(`./icons/${theme}/additionalLinks/Community`);
  const DailyFXIcon = await import(`./icons/${theme}/additionalLinks/DailyFX`);
  const FeedbackIcon = await import(`./icons/${theme}/additionalLinks/Feedback`);
  const AcademyIcon = await import(`./icons/${theme}/additionalLinks/Academy`);

  return {
    CONTACT_US: ContactUsIcon.default(),
    ACADEMY: AcademyIcon.default(),
    COMMUNITY: CommunityIcon.default(),
    DAILYFX: DailyFXIcon.default(),
    FEEDBACK: FeedbackIcon.default(),
  };
};

const AdditionalLinks: VFC<WebConfigAdditionalLinksResponse> = ({ title, additionalLinks }) => {
  const intl = useIntl();
  const { name } = useTheme();
  const theme = calculateThemeColor(name);
  const [logo, setLogo] = useState<LogoMap | undefined>();

  useEffect(() => {
    returnLogoMap(theme).then((logoMap) => {
      setLogo(logoMap);
    });
  }, [name]);

  return (
    <div data-testid='additional-links'>
      <SectionHeader title={title} />
      <Carousel
        responsive={responsiveObject}
        ariaLeftButton={intl.formatMessage({ id: 'additionalLinks.carousel.ariaLeftButton' })}
        ariaRightButton={intl.formatMessage({ id: 'additionalLinks.carousel.ariaRightButton' })}
        hidden={true}
      >
        {additionalLinks.map((additionalLink, key) => (
          <BoxContainer key={key}>
            <Box onClick={() => handleClick(additionalLink.link.url)}>
              <TextContainer>
                <div dangerouslySetInnerHTML={{ __html: additionalLink.link.title }} />
              </TextContainer>
              <Box.Logo>{logo?.[additionalLink.linkId]}</Box.Logo>
            </Box>
          </BoxContainer>
        ))}
      </Carousel>
    </div>
  );
};

export default AdditionalLinks;
