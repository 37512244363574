import { CommunityPageDTO, WebSearchResponse, RecordPageDTO, WebConfigSearchResponse } from '@ig-ds/common-types';
import { Search } from '@ig-phoenix/organisms';
import debounce from 'lodash.debounce';
import { useMemo, useState, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocationState } from '../pages/Results';
import { getSearchResults } from '../utils/api';
import SectionHeader from './SectionHeader';

const useDebounceCall = (host?: string) => {
  const [data, setData] = useState<WebSearchResponse | null>();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSearchRequest = async (query: string) => {
    const response = await getSearchResults(query, {}, host);
    setData(response);
    setLoading(false);
  };

  const debouncedEventHandler = useMemo(() => debounce(handleSearchRequest, 400), []);

  const handleChange = async (query: string) => {
    setInputValue(query);
    setLoading(true);
    await debouncedEventHandler(query);
  };

  return { isLoading, data, handleChange, inputValue };
};

type ResultItem = NonNullable<RecordPageDTO> | NonNullable<CommunityPageDTO>;
type SanitizedResultItem = Required<Pick<ResultItem, 'url' | 'title' | 'id'>> & ResultItem;

const isValidResultItem = <T extends ResultItem>(resultItem: T): resultItem is T & SanitizedResultItem =>
  typeof resultItem.url === 'string' && typeof resultItem.title === 'string' && typeof resultItem.id === 'string';

const SearchWithResults: VFC<WebConfigSearchResponse> = ({
  placeholder,
  title,
  viewMoreArticles,
  viewMoreCommunity,
  loading,
  noSearchResults,
  host,
}) => {
  const navigate = useNavigate();
  const { isLoading, data: results, handleChange, inputValue } = useDebounceCall(host);

  const handleResultClick = (url: string) => {
    window.location.href = `${url}?contentOnly=true`;
  };

  const handleViewMoreClick = (section: LocationState['section'], maxResults: number) => {
    const state: LocationState = {
      searchParam: inputValue,
      section,
      maxResults,
    };
    navigate('/results', { state });
  };

  const renderResults = () => {
    if (results) {
      const hasHelpAndSupportPages = results.helpAndSupport.pages.length > 0;
      const hasCommunityPages = results.community.pages.length > 0;

      if (hasHelpAndSupportPages || hasCommunityPages) {
        return (
          <>
            <Search.ResultSection
              key='help-and-support'
              title={results.helpAndSupport.title}
              ctaText={viewMoreArticles}
              onClick={() => handleViewMoreClick('help-and-support', results.helpAndSupport.totalResultsCount)}
            >
              {results.helpAndSupport.pages?.filter(isValidResultItem).map((result) => (
                <Search.ResultItem key={`hs-${result.id}`} onClick={() => handleResultClick(result.url)}>
                  {result.title}
                </Search.ResultItem>
              ))}
            </Search.ResultSection>

            <Search.ResultSection
              key='community'
              title={results.community.title}
              ctaText={viewMoreCommunity}
              onClick={() => handleViewMoreClick('community', results.community.totalResultsCount)}
            >
              {results.community.pages?.filter(isValidResultItem).map((result) => (
                <Search.ResultItem key={`community-${result.id}`} onClick={() => handleResultClick(result.url)}>
                  {result.title}
                </Search.ResultItem>
              ))}
            </Search.ResultSection>
          </>
        );
      }
    }

    return <Search.ResultText>{noSearchResults}</Search.ResultText>;
  };

  return (
    <>
      {!!title && <SectionHeader title={title} />}
      <Search
        loadingText={loading}
        isLoading={isLoading}
        handleChange={({ currentTarget: { value } }) => value && handleChange(value)}
        placeholder={placeholder}
      >
        {renderResults()}
      </Search>
    </>
  );
};

export default SearchWithResults;
