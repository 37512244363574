import { WebConfigTutorialResponse } from '@ig-ds/common-types';
import { List } from '@ig-phoenix/molecules';
import { VFC } from 'react';
import SectionHeader from './SectionHeader';

const handleClick = (url: string) => {
  window.open(url);
};

const Tutorials: VFC<WebConfigTutorialResponse> = ({ tutorialsList, title }) => (
  <div data-testid='tutorials'>
    <SectionHeader title={title} />
    <List>
      {tutorialsList?.map((tutorialList, key) => (
        <List.Item key={key} onClick={() => handleClick(tutorialList.url)}>
          {tutorialList.title}
        </List.Item>
      ))}
    </List>
  </div>
);

export default Tutorials;
