import { List } from '@ig-phoenix/molecules';
import { VFC } from 'react';
import { WebConfigArticleResponse } from '@ig-ds/common-types';
import SectionHeader from './SectionHeader';

const handleClick = (url: string) => {
  window.open(`${url}?contentOnly=true`);
};

const Articles: VFC<WebConfigArticleResponse> = ({ popularArticles, title }) => (
  <div data-testid='articles'>
    <SectionHeader title={title} />
    <List>
      {popularArticles.map((popularArticle, key) => (
        <List.Item key={key} onClick={() => handleClick(popularArticle.url)}>
          {popularArticle.title}
        </List.Item>
      ))}
    </List>
  </div>
);

export default Articles;
