import { VFC } from 'react';
import styled from 'styled-components';

type SectionHeaderProps = { title: string };

const Header = styled.h2(({ theme }) => ({
  fontWeight: 400,
  fontSize: '11px',
  lineHeight: '14px',
  letterSpacing: '2.6px',
  marginTop: '20px',
  marginBottom: '16px',
  color: theme.color.text.secondary.value,
}));

const SectionHeader: VFC<SectionHeaderProps> = ({ title }) => <Header id={title}>{title.toUpperCase()}</Header>;

export default SectionHeader;
