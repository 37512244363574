const hostToEnv: HostToEnv = {
  localhost: 'LOCAL',
  'test.dgsdtu.ig.com': 'TEST',
  'net-dgs.ig.com': 'TEST',
  'uat.dgsdtu.ig.com': 'UAT',
  'web-dgs.ig.com': 'UAT',
  'www.ig.com': 'PROD',
  'web.ig.com': 'UAT',
  'net.ig.com': 'TEST',
  'dgs.ig.com': 'PROD',
  'live-dgs.ig.com': 'PROD',
};

type Environment = 'TEST' | 'UAT' | 'PROD' | 'LOCAL';
type HostToEnv = { [n: string]: Environment };

const getEnvironmentFromHost = (host = ''): Environment => hostToEnv[host] || hostToEnv[window.location.hostname];

const getAWSDomain = (env: string): string => {
  const urls: { [key: string]: string } = {
    TEST: 'test.dgsdtu',
    UAT: 'uat.dgsdtu',
    PROD: 'dgs',
  };

  return urls[env] || urls.TEST;
};

type URLS = Record<Environment, string>;

const getPremDomain = (env: Environment): string => {
  const urls: URLS = {
    TEST: 'https://net.ig.com',
    UAT: 'https://web.ig.com',
    PROD: 'https://www.ig.com',
    LOCAL: window.location.port !== '3001' ? 'https://net.ig.com' : window.location.href.slice(0, -1),
  };

  return urls[env];
};

const getConfigApiBaseUrl = (env: Environment): string => {
  const configApi: URLS = {
    LOCAL: '', // webpack dev server proxy
    TEST: 'https://test-ds-fe-config-service.api.dgsdtu.ig.com/ds-fe-config-service',
    UAT: 'https://uat-ds-fe-config-service.api.dgsdtu.ig.com/ds-fe-config-service',
    PROD: 'https://ds-fe-config-service.api.dgs.ig.com/ds-fe-config-service',
  };

  return configApi[env];
};

export default {
  getEnvironmentFromHost,
  getAWSDomain,
  getPremDomain,
  getConfigApiBaseUrl,
};
