import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import env from '../environment/environment';

interface Args {
  dsn: string;
  version: string;
}

const init = ({ dsn, version }: Args) => {
  const environment = env.getEnvironmentFromHost();

  if (environment === 'UAT' || environment === 'PROD') {
    Sentry.init({
      dsn,
      integrations: [new Integrations.BrowserTracing()],
      environment,
      release: version,
      /* We're only sampling 5% of the transactions for performance monitoring.
       * This is to avoid flooding Sentry with events, which could potentially
       * mean hitting the a cap.
       */
      tracesSampleRate: 0.05,
    });
  }
};

export default { init };
