import {
  SectionCustomProps,
  WebConfigArticleResponse,
  WebConfigComponents,
  WebConfigResponse,
  WebConfigAdditionalLinksResponse,
  WebConfigSearchResponse,
  WebConfigSystemStatusResponse,
  WebConfigTutorialResponse,
} from '@ig-ds/common-types';
import { useContext, useEffect, useState, VFC } from 'react';
import AdditionalLinks from '../components/AdditionalLinks';
import { HostContext } from '../App';
import AppWrapper from '../components/AppWrapper';
import Articles from '../components/Articles';
import Search from '../components/Search';
import SystemStatus from '../components/SystemStatus';
import Tutorials from '../components/Tutorials';
import { getConfig } from '../utils/api';

interface Props {
  name: string;
}

type ComponentOptions = {
  [key: string]: JSX.Element;
};

const configToComponent = (
  componentConfig: WebConfigComponents,
  key: number,
  customProps: SectionCustomProps,
): ComponentOptions => ({
  SEARCH: <Search key={key} {...(componentConfig as WebConfigSearchResponse)} {...customProps} />,
  ARTICLES: <Articles key={key} {...(componentConfig as WebConfigArticleResponse)} {...customProps} />,
  TUTORIALS: <Tutorials key={key} {...(componentConfig as WebConfigTutorialResponse)} {...customProps} />,
  SYSTEM_STATUS: <SystemStatus key={key} {...(componentConfig as WebConfigSystemStatusResponse)} {...customProps} />,
  ADDITIONAL_LINKS: (
    <AdditionalLinks key={key} {...(componentConfig as WebConfigAdditionalLinksResponse)} {...customProps} />
  ),
});

const Home: VFC<Props> = () => {
  const [config, setConfig] = useState<WebConfigResponse | undefined>();

  const host = useContext(HostContext);

  useEffect(() => {
    getConfig(host).then((res) => setConfig(res));
  }, []);

  return (
    <AppWrapper>
      {config?.sections?.map((section, key) => configToComponent(section, key, { host })[section.type])}
    </AppWrapper>
  );
};

export default Home;
