import { WebConfigSystemStatusResponse } from '@ig-ds/common-types';
import { Chevron, Header, Paragraph } from '@ig-phoenix/atoms';
import StyleDictionary from 'ig-style-dictionary';
import { calculateThemeColor } from '@ig-ds/utilities';
import { useEffect, useState, VFC } from 'react';
import styled, { useTheme } from 'styled-components';
import SectionHeader from './SectionHeader';

interface StatusContainerProps {
  border: string;
  color: string;
  variant: 'affirmative' | 'warning' | 'critical' | 'accent';
  icon: VFC;
}

export enum Status {
  NONE = 'NONE',
  MINOR = 'MINOR',
  MAJOR = 'MAJOR',
  CRITICAL = 'CRITICAL',
  MAINTENANCE = 'MAINTENANCE',
}

type StatusMap = {
  [key in Status]: StatusContainerProps;
};

const StatusContainer = styled.button<StatusContainerProps>(({ theme, color, border }) => ({
  width: '100%',
  marginBottom: '30px',
  padding: '8px 16px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  color,
  background: `${theme.color.background.primarySurface.value}`,
  border: `1px solid ${border}`,
  cursor: 'pointer',
}));

const StatusText = styled(Header)<{ color: string }>(({ color }) => ({
  color,
  display: 'flex',
}));

const ChevronContainer = styled.div`
  margin-left: auto;
`;

const IconContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled.div`
  margin-left: 12px;
`;

const resolveStatuses = async (
  status: keyof typeof Status,
  notification: typeof StyleDictionary['color']['notification'],
  theme: string,
) => {
  let icon;

  // eslint-disable-next-line default-case
  switch (status) {
    case 'NONE':
      icon = await import(`./icons/${theme}/systemStatus/SystemsOperational`);
      break;
    case 'MAINTENANCE':
      icon = await import(`./icons/${theme}/systemStatus/ScheduledMaintenance`);
      break;
    case 'CRITICAL':
      icon = await import(`./icons/${theme}/systemStatus/CriticalDisruption`);
      break;
    case 'MINOR':
    case 'MAJOR':
      icon = await import(`./icons/${theme}/systemStatus/Disruption`);
      break;
  }

  const statusMap: StatusMap = {
    NONE: {
      variant: 'affirmative',
      color: notification.affirmative.iconBackground.value,
      border: notification.affirmative.iconBackground.value,
      icon: icon.default(),
    },
    MINOR: {
      variant: 'warning',
      color: notification.warning.icon.value,
      border: notification.warning.icon.value,
      icon: icon.default(),
    },
    MAJOR: {
      variant: 'warning',
      color: notification.warning.icon.value,
      border: notification.warning.icon.value,
      icon: icon.default(),
    },
    CRITICAL: {
      variant: 'critical',
      color: notification.critical.icon.value,
      border: notification.critical.icon.value,
      icon: icon.default(),
    },
    MAINTENANCE: {
      variant: 'accent',
      color: notification.informative.icon.value,
      border: notification.informative.icon.value,
      icon: icon.default(),
    },
  };

  return statusMap;
};

const SystemStatus: VFC<WebConfigSystemStatusResponse> = ({ systemStatusLink, status, title, description }) => {
  const {
    color: { notification },
    name,
  } = useTheme();
  const [statusMap, setStatusMap] = useState<StatusMap>();
  const theme = calculateThemeColor(name);

  useEffect(() => {
    resolveStatuses(status, notification, theme).then((newStatusMap) => {
      setStatusMap(newStatusMap);
    });
  }, [status, name]);

  const handleClick = () => {
    window.location.href = systemStatusLink.url;
  };

  return statusMap ? (
    <>
      <SectionHeader title={title} />
      <StatusContainer data-testid='system-status' {...statusMap[status]} onClick={handleClick}>
        <IconContainer>{statusMap[status].icon}</IconContainer>
        <ContentContainer>
          <StatusText variant='h3' size='small' color={statusMap[status].color}>
            {systemStatusLink.title}
          </StatusText>
          <Paragraph variant='secondary' size='small'>
            {description}
          </Paragraph>
        </ContentContainer>
        <ChevronContainer>
          <Chevron variant={statusMap[status].variant} />
        </ChevronContainer>
      </StatusContainer>
    </>
  ) : (
    <></>
  );
};

export default SystemStatus;
