import { Header, SearchResult, Sublabel } from '@ig-phoenix/atoms';
import { InfiniteScroll, useInfiniteScroll } from '@ig-phoenix/molecules';
import { useContext } from 'react';
import { HostContext } from '../App';
import { LocationState } from '../pages/Results';
import { getSearchResults } from '../utils/api';

const RESULTS_PER_PAGE = 25;

export interface ResultsListProps {
  searchParam: string;
  section: LocationState['section'];
  maxResults: number;
  translations: {
    errorText: string;
    errorCta: string;
    lastResultText: string;
  };
}

const createFetcher = (param: string, section: LocationState['section'], host?: string) => async (page: number) => {
  const newResults = await getSearchResults(
    param,
    {
      perPage: RESULTS_PER_PAGE,
      page,
    },
    host,
  );

  const newPage = section === 'community' ? newResults?.community?.pages : newResults?.helpAndSupport?.pages;

  if (newPage) {
    return newPage;
  }

  return null;
};

const ResultsList = ({ searchParam, section, maxResults, translations }: ResultsListProps) => {
  const host = useContext(HostContext);
  const { loadMoreItems, items, hasError, hasMore, isLoading } = useInfiniteScroll({
    fetchItems: createFetcher(searchParam, section, host),
    maxResults,
  });

  return (
    <InfiniteScroll
      loadMoreItems={loadMoreItems}
      hasMore={hasMore}
      hasError={hasError}
      isLoading={isLoading}
      translations={translations}
    >
      {items.map((item) => (
        <SearchResult
          key={item.id}
          onClick={() => {
            if (item.url) {
              window.location.href = `${item.url}?contentOnly=true`;
            }
          }}
        >
          <Header size='small' textAlign='left' variant='h5'>
            {item.title}
          </Header>
          {item.contentPreview && <Sublabel htmlFor='search-result-item'>{`${item.contentPreview}...`}</Sublabel>}
        </SearchResult>
      ))}
    </InfiniteScroll>
  );
};

export default ResultsList;
