import { Component, ErrorInfo, ReactNode } from 'react';
import Log from '../log/log';

type Props = {
  children: ReactNode;
};

class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch = (error: Error, info: ErrorInfo) => {
    Log.error(error, info);
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <main
          style={{
            flexDirection: 'column',
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f8fafa',
          }}
        >
          <h1>Something went wrong!</h1>
        </main>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
